<template>
    <div class="course">
        <div class="top-head">
            <!-- <div class="return" @click="$router.go(-1)"><img src="@/assets/images/return.png"></div> -->
            <div class="text-center">已购</div>
            <div class="edit" @click="exit">退出</div>
        </div>
        <ul class="course-list" v-if="courseData.length>0">
            <li v-for="item in courseData" @click="$router.push({path:'/course/find/detail',query:{id:item.id}})">
                <img :src="domainUrl+item.photo" alt="">
                <div class="info">
                    <div class="title ell">{{item.class_name}}</div>
                    <div class="text ell">
                        <span>{{item.count}}讲</span>
                        {{item.feature}}
                    </div>
                    <div class="detail">
                        <button v-on:click.stop="$router.push({path:'/course/find/play',query:{id:item.id,index:0}})">立即学习</button>
                        <span>已购</span>
                    </div>
                </div>
            </li>
        </ul>
        <div class="commment-none" v-else><img src="@/assets/images/none.png"><br>暂无内容</div>
    </div>
</template>
<script>
import Vue from "vue";
export default {
    data() {
        return {
            courseData: [],
            from: Vue.getUser().from,
        }
    },
    created() {
        this.getList();
    },
    methods: {
        exit() {
            if (this.from == 2) {
                window.location.href = 'http://aunt.hailuoguniang.com/#/home'
            } else if (this.from == 1) {
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                if (isiOS) {
                    window.webkit.messageHandlers.Exit.postMessage(null);
                } else if (isAndroid) {
                    SharedUtils.exitPage();
                }
            }
        },
        getList() {
            this.$ajax.send({
                url: "/course/order_list",
                data: { uid: Vue.getUser().uid },
                type: "post",
                success: data => {
                    if (!!data && data.code == "1001") {
                        this.courseData = data.data;
                    }
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.course-list {
    padding: 88px 0 98px 0;
    li {
        border-bottom: 16px solid #f9f9f9;
        padding: 36px 32px;
        margin: 0;
        &:last-child {
            border-bottom: none;
        }
        .detail {
            button {
                height: 40px;
                border-radius: 20px;
                background: #d51f13;
                border: none;
                color: #fff;
                font-size: 26px;
                float: right;
                padding: 0 20px;
            }
            span {
                width: 103px;
                height: 40px;
                display: inline-block;
                text-align: center;
                color: #fff;
                font-size: 26px;
                background: #fde093;
            }
        }
    }
}
.commment-none {
    padding: 160px 20px;
    text-align: center;
    font-size: 28px;
    color: #959595;

    img {
        width: 70%;
    }
}
</style>